// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cannabislaws-js": () => import("./../src/pages/cannabislaws.js" /* webpackChunkName: "component---src-pages-cannabislaws-js" */),
  "component---src-pages-cumulonimbus-js": () => import("./../src/pages/cumulonimbus.js" /* webpackChunkName: "component---src-pages-cumulonimbus-js" */),
  "component---src-pages-graphics-js": () => import("./../src/pages/graphics.js" /* webpackChunkName: "component---src-pages-graphics-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mypiece-js": () => import("./../src/pages/mypiece.js" /* webpackChunkName: "component---src-pages-mypiece-js" */),
  "component---src-pages-natureofthings-js": () => import("./../src/pages/natureofthings.js" /* webpackChunkName: "component---src-pages-natureofthings-js" */),
  "component---src-pages-portfoliosite-js": () => import("./../src/pages/portfoliosite.js" /* webpackChunkName: "component---src-pages-portfoliosite-js" */)
}

